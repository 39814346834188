import React, {useCallback} from 'react';
import {Button, ButtonGroup, LegacyStack} from '@shopify/polaris';
import MigrationProgressBar from '../../migrationProgressBar/migrationProgressBar';
import {navigate} from "gatsby";
import './modalFooter.css';

const ModalFooter = (props) => {
  const {
    isDone,
    selectedResources,
    isLoading,
    setIsLoading,
    migrationProgress,
    migrateHandler,
    handleChangeModalActive,
    setActive,
  } = props;

  const doneHandler = useCallback(() => {
    navigate('/app/offers', {
      replace: true,
    })
    setActive(false)
  }, []);

  const progressBarStyles = {
    width: '83%',
    padding: '0 5%'
  }

  return (
    <LegacyStack distribution='fill'>
      {
        isDone
          ? (
            <LegacyStack distribution='trailing'>
              <Button loading={isLoading}  onClick={doneHandler} variant="primary">
                Done
              </Button>
            </LegacyStack>
          )
          : (
            <div className='ModalFooterWrapper'>
              <MigrationProgressBar styles={progressBarStyles} progress={migrationProgress} isLoading={isLoading}/>
              <LegacyStack distribution='trailing'>
                <ButtonGroup>
                  <Button loading={isLoading} onClick={() => { setActive(false)}}>Cancel</Button>
                  <Button
                    loading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      migrateHandler();
                    }}

                    disabled={selectedResources?.length === 0}
                    variant="primary">Migrate</Button>
                </ButtonGroup>
              </LegacyStack>
            </div>
          )
      }
    </LegacyStack>
  );
};

export default ModalFooter;